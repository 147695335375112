import { PortalTokenRequest } from "../../../../authentication/PortalTokenRequest";

export class ExternalAddCurtisTrailRawRequest {
    trailCode: string;
    metadata: string;
    token: PortalTokenRequest

    constructor(trailCode: string, metadata: string, token: PortalTokenRequest) {
        this.trailCode = trailCode;
        this.metadata = metadata;
        this.token = token;
    }
}
