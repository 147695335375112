import { SET_ID, SET_NAME, SET_LOGIN, SET_EMAIL, SET_TOKEN, SET_COMPANY, SET_IP, SET_CC } from './actionTypes';

interface UserState {
    id: number;
    name: string;
    login: string;
    email: string;
    token: string;
    company: string;
    ip: string;
    cc: string;
}

const initialState: UserState = {
    id: 0,
    name: '',
    login: '',
    email: '',
    token: '',
    company: '',
    ip: '',
    cc: '',
};

type UserAction =
    | { type: typeof SET_ID; payload: number }
    | { type: typeof SET_NAME; payload: string }
    | { type: typeof SET_LOGIN; payload: string }
    | { type: typeof SET_EMAIL; payload: string }
    | { type: typeof SET_TOKEN; payload: string }
    | { type: typeof SET_COMPANY; payload: string }
    | { type: typeof SET_IP; payload: string }
    | { type: typeof SET_CC; payload: string };


export function userReducer(state: UserState = initialState, action: UserAction): UserState {
    switch (action.type) {
        case SET_ID:
            return { ...state, id: action.payload };
        case SET_NAME:
            return { ...state, name: action.payload };
        case SET_LOGIN:
            return { ...state, login: action.payload };
        case SET_EMAIL:
            return { ...state, email: action.payload };
        case SET_TOKEN:
            return { ...state, token: action.payload };
        case SET_COMPANY:
            return { ...state, company: action.payload };
        case SET_IP:
            return { ...state, ip: action.payload };
        case SET_CC:
            return { ...state, cc: action.payload };

        default:
            return state;
    }
}
