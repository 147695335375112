import React from 'react';
import './UserLicenseManager.css';

const UserLicenseManager: React.FC = () => {
  return (
    <div className="user-license-manager">
      <h1>Software Licenses</h1>
      <p>This part is under construction.</p>
    </div>
  );
}

export default UserLicenseManager;
