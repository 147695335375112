import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from './Header';
import LeftPanel from './LeftPanel';
import { AuthenticatedPortalUseShared } from '../authentication/AuthenticatedPortalUseShared';
import './Home.css';

interface HomeProps {
    user: AuthenticatedPortalUseShared | null;
}

const Home: React.FC<HomeProps> = ({ user }) => {
    return (
        <div className="home-layout">
            <Header user={user} />
            <div className="main-layout">
                <LeftPanel />
                <Outlet /> {/* routes */}
            </div>
        </div>
    );
};

export default Home;
