import axios from 'axios';
import { PortalApplicationNotesListRequest } from './request/PortalApplicationNotesListRequest';
import { ExternalApplicationNotesListResponse } from './response/ExternalApplicationNotesListResponse';

const BASE_URL = process.env.REACT_APP_EXTERNAL_WS_URL;

const list = async (request: PortalApplicationNotesListRequest): Promise<ExternalApplicationNotesListResponse[]> => {
    console.log('listCdev function called with request:', request);
    console.log('BASE_URL:', BASE_URL);

    try {
        console.log('Making POST request to URL:', `${BASE_URL}/CdevList`);
        console.log('With headers:', {
            'Content-Type': 'application/json' // Ensure the content type is JSON
        });

        const response = await axios.post<ExternalApplicationNotesListResponse[]>(`${BASE_URL}/ApplicationNotesList`, request, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        console.log('Received response from server:', response);

        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.error('Axios error during Cdev list retrieval:', error);
            console.error('Error details:', {
                message: error.message,
                name: error.name,
                stack: error.stack,
                config: error.config,
                code: error.code,
                request: error.request,
                response: error.response,
            });
        } else {
            console.error('Unexpected error during Cdev list retrieval:', error);
        }

        throw error;
    }
};

const PortalApplicationNotesListClient = {
    list,
};

export default PortalApplicationNotesListClient;
