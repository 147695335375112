import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import { Provider, useDispatch } from 'react-redux';
import store from './redux/store';
import Home from './pages/Home';
import Dashboard from './pages/Dashboard';
import Login from './pages/authentication/Login';
import Cookie from 'js-cookie';
import { AuthenticatedPortalUseShared } from './authentication/AuthenticatedPortalUseShared';
import 'bootstrap/dist/css/bootstrap.min.css';
import CustomerSupportMain from './pages/customer-support/CustomerSupportMain';
import UserLicenseManager from './pages/user-license-manager/UserLicenseManager';
import CdevListUI from './components/cdev-explorer/ui/CdevListUI';
import { AppConfig } from './AppConfig';
import { setId, setName, setLogin as setReduxLogin, setEmail, setToken, setIp, setCompany } from './redux/actions';
import ApplicationNotesListUI from './components/application-notes/ui/ApplicationNotesListUI';
import ManualListUI from './components/manual/ui/ManualListUI';
import ReleaseNotesListUI from './components/release-notes/ui/ReleaseNotesListUI';
import DynoProcessListUI from './components/dyno-process/ui/DynoProcessListUI';
import SharedWithMeUI from './components/shared-with-me/ui/SharedWithMeUI';


const App: React.FC = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState<AuthenticatedPortalUseShared | null>(null);
  const dispatch = useDispatch(); // Initialize dispatch to update Redux store

  useEffect(() => {
    const cookieUser = Cookie.get(AppConfig.COOKIE_NAME);
    if (cookieUser) {
      const user: AuthenticatedPortalUseShared = JSON.parse(cookieUser);
      setUser(user);

      // Dispatch actions to set user data in Redux store
      dispatch(setId(user.id));
      dispatch(setName(user.name));
      dispatch(setReduxLogin(user.login));
      dispatch(setEmail(user.email));
      dispatch(setToken(user.token));
      dispatch(setIp(user.ip));
      dispatch(setCompany(user.company));
    } else {
      navigate('/login');
    }
  }, [navigate, dispatch]);

  return (
    <Provider store={store}>
      <Routes>
        <Route path="/login" element={<Login />} />

        <Route path="/" element={<Home user={user} />}>
          <Route index element={<Dashboard />} />
          <Route path="license-manager" element={<UserLicenseManager />} />


          <Route path="cdev-explorer">
            <Route index element={<CdevListUI />} />
          </Route>

          <Route path="application-notes">
            <Route index element={<ApplicationNotesListUI />} />
          </Route>

          <Route path="manual">
            <Route index element={<ManualListUI />} />
          </Route>

          <Route path="release-notes">
            <Route index element={<ReleaseNotesListUI />} />
          </Route>

          <Route path="dyno-process">
            <Route index element={<DynoProcessListUI />} />
          </Route>

          <Route path="shared-with-me">
            <Route index element={<SharedWithMeUI />} />
          </Route>


          <Route path="customer-support" element={<CustomerSupportMain />} />


        </Route>


      </Routes>
    </Provider>
  );
};

export default App;
