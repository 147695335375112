import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { loginAuth } from '../../authentication/client/LoginAuthClient';
import { LoginRequest } from '../../authentication/LoginRequest';
import { AuthenticatedPortalUseShared } from '../../authentication/AuthenticatedPortalUseShared';
import Cookie from 'js-cookie';
import './Login.css';
import { AppConfig } from '../../AppConfig';
import { setId, setName, setLogin as setReduxLogin, setEmail, setToken, setIp, setCompany, setCC } from '../../redux/actions';

const Login: React.FC = () => {
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState(''); // New state for error message
    const navigate = useNavigate();
    const dispatch = useDispatch(); // Initialize dispatch

    const handleLogin = async () => {
        const request: LoginRequest = { login, password };
        try {
            const user: AuthenticatedPortalUseShared = await loginAuth(request);

            console.log('user after login',user);

            // Dispatch actions to save user info in Redux store
            dispatch(setId(user.id));
            dispatch(setName(user.name));
            dispatch(setReduxLogin(user.login));
            dispatch(setEmail(user.email));
            dispatch(setToken(user.token));
            dispatch(setIp(user.ip));
            dispatch(setCompany(user.company));
            dispatch(setCC(user.cc));

            // Set cookie
            Cookie.set(AppConfig.COOKIE_NAME, JSON.stringify({
                id: user.id,
                name: user.name,
                login: user.login,
                email: user.email,
                token: user.token,
                ip: user.ip,
                company: user.company,
                cc: user.cc
            }), { expires: 365 });

            navigate('/');

        } catch (error) {
            console.error('Login failed', error);
            setErrorMessage('Login failed for the provided credentials.'); // Set the error message
        }
    };

    return (
        <div className="login-container">
            <div className="login-box">
                <div className="logo-container">
                    <img src="/logo-v3.png" className="logo" alt="Logo" />
                    <img src="/KohlerEnergy.svg" className="KohlerLogo" alt="Kohler Logo" />
                </div>
                <h2>Login</h2>
                <input
                    type="text"
                    value={login}
                    onChange={(e) => setLogin(e.target.value)}
                    placeholder="Login"
                    className='global-textbox-regular'
                />
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                    className='global-textbox-regular'
                />
                {errorMessage && <div className="error-message">{errorMessage}</div>} {/* Display error message */}
                <button onClick={handleLogin}>Login</button>
            </div>
        </div>
    );
};

export default Login;
