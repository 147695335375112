import axios from 'axios';
import { ExternalAddCurtisTrailRawRequest } from './request/ExternalAddCurtisTrailRawRequest';
import { ExternalAddCurtisTrailRawResponse } from './response/ExternalAddCurtisTrailRawResponse';

const BASE_URL = process.env.REACT_APP_EXTERNAL_WS_URL;


const addCurtisTrailRaw = async (request: ExternalAddCurtisTrailRawRequest): Promise<ExternalAddCurtisTrailRawResponse> => {

    console.log('0813_e2, BASE_URL', BASE_URL);

    try {
        const response = await axios.post<ExternalAddCurtisTrailRawResponse>(`${BASE_URL}/CurtisTrailRawAdd`, request);
        return response.data;

    } catch (error) {
        console.error('addCurtisTrailRaw error', error);
        throw error;

    }

};

const CurtisTrailClient = {
    addCurtisTrailRaw
};

export default CurtisTrailClient;
