import axios from 'axios';
import { RequestExternalDirectShareList } from './RequestExternalDirectShareList';
import { ResponseExternalDirectShareList } from './ResponseExternalDirectShareList';

const BASE_URL = process.env.REACT_APP_EXTERNAL_WS_URL;

const listDirectShares = async (directShareListRequest: RequestExternalDirectShareList): Promise<ResponseExternalDirectShareList[]> => {
    try {
        const response = await axios.post<ResponseExternalDirectShareList[]>(`${BASE_URL}/DirectShareList`, directShareListRequest, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.error('Axios error during direct share list retrieval:', error);
        } else {
            console.error('Unexpected error during direct share list retrieval:', error);
        }
        throw error;
    }
};

const SharedWithMeClient = {
    listDirectShares,
};

export default SharedWithMeClient;
