import { createStore, combineReducers } from 'redux';
import { userReducer } from './reducers';

const rootReducer = combineReducers({
    user: userReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

const store = createStore(rootReducer);

export default store;
