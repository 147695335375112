import React, { useState, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { GridReadyEvent, GridApi, ColDef } from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './CdevListUI.css';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AppState } from '../../../redux/store';
import Swal from 'sweetalert2';
import { PortalCdevListRequest } from '../lib/PortalCdevListRequest';
import PortalCdevListClient from '../lib/PortalCdevListClient';
import { PortalTokenRequest } from '../../../authentication/PortalTokenRequest';
import CurtisTrailClient from '../../curtis-trail/lib/CurtisTrailClient';
import { ICellRendererParams } from 'ag-grid-community';
import { ExternalCdevTrailMetadata } from '../../curtis-trail/lib/request/ExternalCdevTrailMetadata';
import { ExternalAddCurtisTrailRawRequest } from '../../curtis-trail/lib/request/ExternalAddCurtisTrailRawRequest';
import { ExternalCdevListResponse } from '../lib/ExternalCdevListResponse';
import { AppConfig } from '../../../AppConfig';

const CdevListUI: React.FC = () => {
    const id = useSelector((state: AppState) => state.user.id);
    const name = useSelector((state: AppState) => state.user.name);
    const login = useSelector((state: AppState) => state.user.login);
    const email = useSelector((state: AppState) => state.user.email);
    const token = useSelector((state: AppState) => state.user.token);
    const company = useSelector((state: AppState) => state.user.company);
    const ip = useSelector((state: AppState) => state.user.ip);

    const [_cdevList, _setCdevList] = useState<ExternalCdevListResponse[]>([]);
    const [gridApi, setGridApi] = useState<GridApi | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    const showAlert = (message: string) => {
        Swal.fire({
            title: 'Attention',
            text: message,
            icon: 'info',
            confirmButtonText: 'Ok'
        });
    };



    const loadCdevList = async () => {
        if (isLoading) return;

        setIsLoading(true);

        try {
            const tokenRequest = new PortalTokenRequest(token);
            const request = new PortalCdevListRequest(tokenRequest);
            const response: ExternalCdevListResponse[] = await PortalCdevListClient.listCdev(request);

            console.log('0813_a3, response', response);

            const transformedResponse = response.map(item => ({
                ...item,
                externalDownloadLinkUrl: item.externalDownloadLinkUrl || ''  // Keep it if empty
            }));

            console.log('0813_a4, transformedResponse', transformedResponse);

            _setCdevList(transformedResponse);

            console.log('0813_a5, _cdevList', _cdevList);

        } catch (error) {
            console.error('loadCdevList', error);
            showAlert('Failed to load CDEV list.');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        console.log('0813_a6, _cdevList', _cdevList);
    }, [_cdevList]);


    useEffect(() => {
        loadCdevList();
    }, [token]);



    const handleDownloadAndLog = async (params: ICellRendererParams) => {
        
        let currentCdev = params.data as ExternalCdevListResponse;
        const browserName = navigator.userAgent;
        let browserVersion = "Unknown";
        const language = navigator.language;
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        const ua = navigator.userAgent;
        const versionMatch = ua.match(/(Firefox|Chrome|Safari|Opera|Edge)\/(\d+)/);
        if (versionMatch && versionMatch.length > 2) {
            browserVersion = `${versionMatch[1]} ${versionMatch[2]}`;
        }

        const metadataObj = new ExternalCdevTrailMetadata(
            currentCdev.id,
            currentCdev.fileName!,
            currentCdev.version!,
            currentCdev.externalDownloadLinkUrl!,
            name,
            login,
            email,
            company,
            ip,
            browserName,
            browserVersion,
            language,
            timeZone,
            0, 0, 0, 0
        );

        const metadata = JSON.stringify(metadataObj);
        const tokenRequest = new PortalTokenRequest(token);
        const request = new ExternalAddCurtisTrailRawRequest(AppConfig.TRAIL_TYPE_CODE_EXTERNAL_FOS, metadata, tokenRequest);

        console.log('0813_e1, request', request);

        try {
            await CurtisTrailClient.addCurtisTrailRaw(request);

        } catch (error) {
            console.error('Error logging download:', error);
            showAlert('Failed to log download.');

        }

        const baseUrl = window.location.origin;
        const downloadUrl = new URL(currentCdev.externalDownloadLinkUrl!, baseUrl).href;
        if (downloadUrl) {
            window.open(downloadUrl, '_blank');
        } else {
            showAlert('No download link available for this file.');
        }

        // if (currentCdev.externalDownloadLinkUrl) {
        //     window.open(currentCdev.externalDownloadLinkUrl, '_blank');
        // } else {
        //     showAlert('No download link available for this file.');
        // }
    };

    const onGridReady = (params: GridReadyEvent) => {
        setGridApi(params.api);
        params.api.sizeColumnsToFit();
    };

    const onUploadCdevFileClick = () => {
        navigate('/cdev-explorer/upload');
    };

    const onRefreshClick = () => {
        loadCdevList();
    };






    const CdevListColumnDefs: ColDef[] = [
        // {
        //     headerName: "ID",
        //     field: "id",
        //     flex: 0.5, // Smaller portion
        //     sortable: true,
        //     filter: 'agNumberColumnFilter',
        //     cellRenderer: (params: ICellRendererParams) => <span>{params.value}</span>,
        // },
        {
            headerName: "",
            field: "externalDownloadLinkUrl",
            width: 50,  // Fixed width of 50px to ensure visibility
            cellRenderer: (params: ICellRendererParams) => {
                return params.value ? (
                    <div className="download-icon-container">
                        <img
                            src="/icons/download.svg"
                            alt="Download"
                            className="download-grid-icon-button"
                            onClick={() => handleDownloadAndLog(params)}
                        />
                    </div>
                ) : null;
            },
        },
        {
            headerName: "File Name",
            field: "fileName",
            flex: 2, // Larger portion, file name generally needs more space
            sortable: true,
            filter: 'agTextColumnFilter',
            cellRenderer: (params: ICellRendererParams) => (
                <div className="file-name-container">
                    <span>{params.value}</span>
                </div>
            ),
        },
        {
            headerName: "Version",
            field: "version",
            flex: 1, // Medium portion
            sortable: true,
            filter: 'agTextColumnFilter',
        },
        {
            headerName: "Description",
            field: "description",
            flex: 2, // Larger portion, descriptions might be long
            sortable: true,
            filter: 'agTextColumnFilter',
        },
    ];












    return (
        <div className="root-form">
            <div className="page-title">
                <img src="/icons/cdev-explorer.svg" alt="Icon" className="page-icon" />
                CDEV List
            </div>

            <div className="button-group-container">
                <div className="button-group-left">
                    <button className="form-button" onClick={onRefreshClick}>Refresh</button>
                </div>
            </div>

            <div className="stats">Count: {_cdevList.length}</div>

            <div className="ag-theme-alpine" style={{ width: '100%', height: '100%' }}>
                {_cdevList.length > 0 ? (
                    <AgGridReact
                        gridOptions={{
                            columnDefs: CdevListColumnDefs,
                            paginationPageSize: 10,
                        }}
                        rowData={_cdevList}
                        onGridReady={onGridReady}
                        domLayout="autoHeight"
                        pagination={true}
                        rowSelection="multiple"
                    />
                ) : (
                    <div>No data available</div>
                )}
            </div>
        </div>
    );
};

export default CdevListUI;
