import { SET_ID, SET_NAME, SET_LOGIN, SET_EMAIL, SET_TOKEN, SET_COMPANY, SET_IP, SET_CC } from './actionTypes';

export const setId = (id: number) => ({
    type: SET_ID as typeof SET_ID,
    payload: id,
});

export const setName = (name: string) => ({
    type: SET_NAME as typeof SET_NAME,
    payload: name,
});

export const setLogin = (login: string) => ({
    type: SET_LOGIN as typeof SET_LOGIN,
    payload: login,
});

export const setEmail = (email: string) => ({
    type: SET_EMAIL as typeof SET_EMAIL,
    payload: email,
});

export const setToken = (token: string) => ({
    type: SET_TOKEN as typeof SET_TOKEN,
    payload: token,
});

export const setCompany = (company: string) => ({
    type: SET_COMPANY as typeof SET_COMPANY,
    payload: company,
});

export const setIp = (ip: string) => ({
    type: SET_IP as typeof SET_IP,
    payload: ip,
});

export const setCC = (cc: string) => ({
    type: SET_CC as typeof SET_CC,
    payload: cc,
});
