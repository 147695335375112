import React, { useEffect } from 'react';
import './Header.css';
import { AuthenticatedPortalUseShared } from '../authentication/AuthenticatedPortalUseShared';
import Cookie from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { AppConfig } from '../AppConfig';


interface HeaderProps {
    user: AuthenticatedPortalUseShared | null;
}

const Header: React.FC<HeaderProps> = ({ user }) => {
    const navigate = useNavigate();

    useEffect(() => {
        if (user) {
            const cookieContent = Cookie.get(AppConfig.COOKIE_NAME);
            console.log('Cookie content:', cookieContent);
            console.log('User data:', user);
        }
    }, [user]);

    const handleLogout = () => {
        Cookie.remove(AppConfig.COOKIE_NAME);
        navigate('/login');
    };

    return (
        <header className="header">
            <a href="/" className="brand">
                <img
                    src="/logo-v3.png"
                    className="logo"
                    alt="Logo"
                />
                {/* <img
                    src="/KohlerEnergy.svg"
                    className="KohlerLogo"
                    alt="Kohler Logo"
                /> */}
            </a>
            <nav>
                <span className="nav-item">
                    {user?.name && (
                        <>
                            <span className="username">{user.name}</span>
                            <button className="logout" onClick={handleLogout}>Logout</button>
                        </>
                    )}
                </span>
            </nav>
        </header>
    );
};

export default Header;
