export class GenericFileDownloadTrailMetadata {
    particularId: number; // regardless of which table it is coming from
    fileName: string;
    internalDownloadLink: string;
    adUserName: string;
    adDomain: string;
    userFullName: string;
    ip: string;
    browserName: string;
    browserVersion: string;
    language: string;
    timeZone: string;
    applicationNotesId: number;
    manualId: number;
    releaseNotesId: number;
    dynoProcessId: number;
    customerId: number;
    customerCode: string;
    customerName: string;

    constructor(
        particularId: number,
        fileName: string,
        internalDownloadLink: string,
        adUserName: string,
        adDomain: string,
        userFullName: string,
        ip: string,
        browserName: string,
        browserVersion: string,
        language: string,
        timeZone: string,
        applicationNotesId: number,
        manualId: number,
        releaseNotesId: number,
        dynoProcessId: number,
        customerId: number,
        customerCode: string,
        customerName: string
    ) {

        this.particularId = particularId;
        this.fileName = fileName;
        this.internalDownloadLink = internalDownloadLink;
        this.adUserName = adUserName;
        this.adDomain = adDomain;

        this.userFullName = userFullName
        this.ip = ip;
        this.browserName = browserName;
        this.browserVersion = browserVersion;
        this.language = language;
        this.timeZone = timeZone;

        this.applicationNotesId = applicationNotesId;
        this.manualId = manualId;
        this.releaseNotesId = releaseNotesId;
        this.dynoProcessId = dynoProcessId;

        this.customerId = customerId;
        this.customerCode = customerCode;
        this.customerName = customerName;
    }
}