import React, { useState, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { GridReadyEvent, GridApi, ColDef } from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './DynoProcessListUI.css';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AppState } from '../../../redux/store';
import Swal from 'sweetalert2';
import { PortalDynoProcessListRequest } from '../lib/request/PortalDynoProcessListRequest';
import { ExternalDynoProcessListResponse } from '../lib/response/ExternalDynoProcessListResponse';
import PortalDynoProcessListClient from '../lib/PortalDynoProcessListClient';
import { PortalTokenRequest } from '../../../authentication/PortalTokenRequest';
import { ExternalAddCurtisTrailRawRequest } from '../../curtis-trail/lib/request/ExternalAddCurtisTrailRawRequest';
import CurtisTrailClient from '../../curtis-trail/lib/CurtisTrailClient';
import { ICellRendererParams } from 'ag-grid-community';
import { ExternalCdevTrailMetadata } from '../../curtis-trail/lib/request/ExternalCdevTrailMetadata';
import { AppConfig } from '../../../AppConfig';

const DynoProcessListUI: React.FC = () => {
    const id = useSelector((state: AppState) => state.user.id);
    const name = useSelector((state: AppState) => state.user.name);
    const login = useSelector((state: AppState) => state.user.login);
    const email = useSelector((state: AppState) => state.user.email);
    const token = useSelector((state: AppState) => state.user.token);
    const company = useSelector((state: AppState) => state.user.company);
    const ip = useSelector((state: AppState) => state.user.ip);

    const [_dynoProcessList, _setDynoProcessList] = useState<ExternalDynoProcessListResponse[]>([]);
    const [gridApi, setGridApi] = useState<GridApi | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    const showAlert = (message: string) => {
        Swal.fire({
            title: 'Attention',
            text: message,
            icon: 'info',
            confirmButtonText: 'Ok'
        });
    };

    const loadDynoProcessList = async () => {
        if (isLoading) return;

        setIsLoading(true);

        try {
            const tokenRequest = new PortalTokenRequest(token);
            const request = new PortalDynoProcessListRequest(tokenRequest);
            const response: ExternalDynoProcessListResponse[] = await PortalDynoProcessListClient.list(request);

            const transformedResponse = response.map(item => ({
                ...item,
                externalDownloadLinkUrl: item.externalDownloadLinkUrl || ''  // Keep it if empty
            }));

            _setDynoProcessList(transformedResponse);

        } catch (error) {
            console.error('loadDynoProcessList', error);
            showAlert('Failed to load Dyno Process list.');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        loadDynoProcessList();
    }, [token]);

    const handleDownloadAndLog = async (params: ICellRendererParams) => {
        
        let currentDynoProcess = params.data as ExternalDynoProcessListResponse;
        const browserName = navigator.userAgent;
        let browserVersion = "Unknown";
        const language = navigator.language;
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        const ua = navigator.userAgent;
        const versionMatch = ua.match(/(Firefox|Chrome|Safari|Opera|Edge)\/(\d+)/);
        if (versionMatch && versionMatch.length > 2) {
            browserVersion = `${versionMatch[1]} ${versionMatch[2]}`;
        }

        const metadataObj = new ExternalCdevTrailMetadata(
            0,
            currentDynoProcess.fileName!,
            currentDynoProcess.version!,
            currentDynoProcess.externalDownloadLinkUrl!,
            name,
            login,
            email,
            company,
            ip,
            browserName,
            browserVersion,
            language,
            timeZone,
            0, 
            0, 
            0,
            currentDynoProcess.id
        );

        const metadata = JSON.stringify(metadataObj);
        const tokenRequest = new PortalTokenRequest(token);
        const request = new ExternalAddCurtisTrailRawRequest(AppConfig.TRAIL_TYPE_CODE_EXTERNAL_DYNO_PROCESS, metadata, tokenRequest);

        try {
            await CurtisTrailClient.addCurtisTrailRaw(request);

        } catch (error) {
            console.error('Error logging download:', error);
            showAlert('Failed to log download.');
        }

        const baseUrl = window.location.origin;
        const downloadUrl = new URL(currentDynoProcess.externalDownloadLinkUrl!, baseUrl).href;
        if (downloadUrl) {
            window.open(downloadUrl, '_blank');
        } else {
            showAlert('No download link available for this file.');
        }

        // if (currentDynoProcess.externalDownloadLinkUrl) {
        //     window.open(currentDynoProcess.externalDownloadLinkUrl, '_blank');
        // } else {
        //     showAlert('No download link available for this file.');
        // }
    };

    const onGridReady = (params: GridReadyEvent) => {
        setGridApi(params.api);
        params.api.sizeColumnsToFit();
    };

    const onRefreshClick = () => {
        loadDynoProcessList();
    };

    const DynoProcessListColumnDefs: ColDef[] = [
        {
            headerName: "ID",
            field: "id",
            flex: 0.5,
            sortable: true,
            filter: 'agNumberColumnFilter',
            cellRenderer: (params: ICellRendererParams) => <span>{params.value}</span>,
        },
        {
            headerName: "",
            field: "externalDownloadLinkUrl",
            width: 50,
            cellRenderer: (params: ICellRendererParams) => {
                return params.value ? (
                    <div className="download-icon-container">
                        <img
                            src="/icons/download.svg"
                            alt="Download"
                            className="download-grid-icon-button"
                            onClick={() => handleDownloadAndLog(params)}
                        />
                    </div>
                ) : null;
            },
        },
        {
            headerName: "File Name",
            field: "fileName",
            flex: 2,
            sortable: true,
            filter: 'agTextColumnFilter',
            cellRenderer: (params: ICellRendererParams) => (
                <div className="file-name-container">
                    <span>{params.value}</span>
                </div>
            ),
        },
        {
            headerName: "Version",
            field: "version",
            flex: 1,
            sortable: true,
            filter: 'agTextColumnFilter',
        },
        {
            headerName: "Description",
            field: "description",
            flex: 2,
            sortable: true,
            filter: 'agTextColumnFilter',
        },
    ];

    return (
        <div className="root-form">
            <div className="page-title">
                <img src="/icons/dyno-process.svg" alt="Icon" className="page-icon" />
                Dyno Process List
            </div>

            <div className="button-group-container">
                <div className="button-group-left">
                    <button className="form-button" onClick={onRefreshClick}>Refresh</button>
                </div>
            </div>

            <div className="stats">Count: {_dynoProcessList.length}</div>

            <div className="ag-theme-alpine" style={{ width: '100%', height: '100%' }}>
                {_dynoProcessList.length > 0 ? (
                    <AgGridReact
                        gridOptions={{
                            columnDefs: DynoProcessListColumnDefs,
                            paginationPageSize: 10,
                        }}
                        rowData={_dynoProcessList}
                        onGridReady={onGridReady}
                        domLayout="autoHeight"
                        pagination={true}
                        rowSelection="multiple"
                    />
                ) : (
                    <div>No data available</div>
                )}
            </div>
        </div>
    );
};

export default DynoProcessListUI;