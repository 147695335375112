import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './LeftPanel.css';

const LeftPanel = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [isCollapsed, setIsCollapsed] = useState(false);

    const [links, setLinks] = useState([
        
        // { title: 'Software Licenses', path: '/license-manager/', icon: '/icons/license-management.svg' },
        
        { title: 'Application Notes', path: '/application-notes/', icon: '/icons/application-notes.svg' },
        { title: 'FOS Files', path: '/cdev-explorer/', icon: '/icons/cdev-explorer.svg' },
        { title: 'Dyno Process', path: '/dyno-process/', icon: '/icons/dyno-process.svg' },
        { title: 'Manual', path: '/manual/', icon: '/icons/manual.svg' },
        { title: 'Release Notes', path: '/release-notes/', icon: '/icons/releases.svg' },
        { title: 'Shared With Me', path: '/shared-with-me/', icon: '/icons/shared-with-me.svg' },
        

        // { title: 'Support', path: '/customer-support/', icon: '/icons/customer-support.svg' },

        // { title: 'Customer Management', path: '/license-manager/', icon: '/icons/customer-management.svg' },
        // { title: 'Periodic Reports', path: '/license-manager/', icon: '/icons/periodic-report.svg' },
        // { title: 'California EV', path: '/license-manager/', icon: '/icons/ev.svg' },
        // { title: 'Releases', path: '/license-manager/', icon: '/icons/releases.svg' },


    ]);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const filteredLinks = links.filter(link =>
        link.title.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className={`sidebar ${isCollapsed ? 'collapsed' : 'expanded'}`}>
            <button className="collapse-btn" onClick={() => setIsCollapsed(!isCollapsed)}>
                {isCollapsed ? '⮞' : '⮜'}
            </button>
            {!isCollapsed && (
                <input
                    type="text"
                    className="search-input"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                />
            )}
            
            <ul className="menu-list">
                {filteredLinks.map((link, index) => (
                    <li className="menu-item" key={index}>
                        <Link to={link.path} className="menu-link">
                            <img src={link.icon} alt="Icon" className="link-icon" />
                            {!isCollapsed && link.title}
                        </Link>
                    </li>
                ))}
            </ul>
            
        </div>
    );
};

export default LeftPanel;
