import axios from 'axios';
import { LoginRequest } from '../LoginRequest';
import { AuthenticatedPortalUseShared } from '../AuthenticatedPortalUseShared';

const BASE_URL = process.env.REACT_APP_EXTERNAL_WS_URL;

export const loginAuth = async (request: LoginRequest): Promise<AuthenticatedPortalUseShared> => {
    try {
        const response = await axios.post<AuthenticatedPortalUseShared>(`${BASE_URL}/LoginAuth`, request);
        return response.data;
    } catch (error) {
        console.error('loginAuth error', error);
        throw error;
    }
};
