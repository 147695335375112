import React from 'react';
import './CustomerSupportMain.css';

const CustomerSupportMain: React.FC = () => {
  return (
    <div className="customer-support-main">
      <h1>Customer Support</h1>
      <p>This part is under construction.</p>
    </div>
  );
}

export default CustomerSupportMain;
