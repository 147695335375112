import { PortalTokenRequest } from "../../../authentication/PortalTokenRequest";

export class RequestExternalDirectShareList {
    token: PortalTokenRequest;
    cc: string;

    constructor(token: PortalTokenRequest, cc: string) {
        this.token = token;
        this.cc = cc;
    }
}
