import React from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../redux/store';
import './Dashboard.css';

const Dashboard: React.FC = () => {
    const user = useSelector((state: AppState) => state.user); // Access user data from Redux store

    return (
        <div className="dashboard">
            <h1>Curtis-Internal Version History</h1>
            <p>Welcome, {user.name}!</p>
            <p>New features are on the way.</p>

            {/* The rest of your component code */}
        </div>
    );
};

export default Dashboard;
