export class ExternalCdevTrailMetadata {
    cdevId: number;
    cdevFileName: string;
    cdevVersion: string;

    cdevExternalDownloadLink: string;
    name: string;
    login: string;
    email: string;
    company: string;
    ip: string;

    browserName: string;
    browserVersion: string;
    language: string;
    timeZone: string;
    applicationNotesId: number;
    manualId: number;
    releaseNotesId: number;
    dynoProcessId: number;

    constructor(
        cdevId: number,
        cdevFileName: string,
        cdevVersion: string,

        cdevExternalDownloadLink: string,
        name: string,
        login: string,
        email: string,
        company: string,
        ip: string,


        browserName: string,
        browserVersion: string,
        language: string,
        timeZone: string,
        applicationNotesId: number,
        manualId: number,
        releaseNotesId: number,
        dynoProcessId: number
    ) {

        this.cdevId = cdevId;
        this.cdevFileName = cdevFileName;
        this.cdevVersion = cdevVersion;

        this.cdevExternalDownloadLink = cdevExternalDownloadLink;
        this.name = name;
        this.login = login;
        this.email = email;
        this.company = company;
        this.ip = ip;

        this.ip = ip;
        this.browserName = browserName;
        this.browserVersion = browserVersion;
        this.language = language;
        this.timeZone = timeZone;

        this.applicationNotesId = applicationNotesId;
        this.manualId = manualId;
        this.releaseNotesId = releaseNotesId;
        this.dynoProcessId = dynoProcessId;
    }
}